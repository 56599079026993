import { hrOverviewStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import CompletedPie from "../hrDashboardComponents/CompletedPie";
import MoodTrend from "../hrDashboardComponents/MoodTrend";
import ParticipationTrend from "../hrDashboardComponents/ParticipationTrend";
import ConstructionIcon from "@mui/icons-material/Construction";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import NotificationComponent from "../hrDashboardComponents/NotificationComponent";
import BookingsTrend from "../hrDashboardComponents/BookingsTrend";
import * as isoWeek from "dayjs/plugin/isoWeek";
import dayjs from "dayjs";
import { DateRangeSelector } from "../hrDashboardComponents/DateRangeSelector";
dayjs.extend(isoWeek);

function Dashboard() {
  const classes = hrOverviewStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [range, setRange] = useState(new Date());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.hrOverviewContainer}>
      <Box className={classes.headingRow}>
        <Typography variant="h1">Dashboard</Typography>
        <Box className={classes.hrBarRight}>
          <Box className={classes.arrowContainer}>
            {/* <NotificationsIcon onClick={handleClick} color="secondary" /> */}
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <NotificationComponent handleClose={handleClose} />
            </Menu>
          </Box>
          <DateRangeSelector range={range} setRange={setRange} />
        </Box>
      </Box>
      <Box className={classes.hrRowOuter}>
        <Box className={classes.hrRow}>
          <Box className={classes.underConstructionContainer}>
            <ConstructionIcon color="primary" />
            <Typography>Under construction</Typography>
          </Box>
        </Box>
        {/* <Box className={classes.hrRow}>
          <CompletedPie range={range} />
          <ParticipationTrend range={range} />
          <MoodTrend range={range} />
          <BookingsTrend range={range} />
        </Box> */}
      </Box>
    </Box>
  );
}

export default Dashboard;
