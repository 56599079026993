import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithHistory = ({ children }) => {
  const history = useNavigate();

  const domain =
    process.env.REACT_APP_AUTH0_DOMAIN || "dev-ltuzu48cxv1txnoj.us.auth0.com";
  const clientId =
    process.env.REACT_APP_CLIENT_ID || "1vN2rK5g8dX1GqtcGCWgbXVooNmPP7FQ";
  const redirectUri = `${window.location.origin}/authorise`;

  const onRedirectCallback = (appState) => {
    history(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "localhost:9090",
        redirectUri: redirectUri,
        scope:
          "openid profile email phone write:questions write:managers write:administrators",
      }}
    >
      {children}
    </Auth0Provider>
  );
};
