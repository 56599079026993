import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useEffect, useState } from "react";
import { newPersonModalStyles, peoplePageStyles } from "../pages/pagesStyles";
import AddIcon from "@mui/icons-material/Add";
import PersonComponent from "./PersonComponent";
import { addPerson, getManagedByOptions, getPeople } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import PersonComponentHeader from "./PersonComponentHeader";

const PeopleLibraryLayout = (props) => {
  const classes = peoplePageStyles();
  const modalClasses = newPersonModalStyles();

  const { people, tab, setPeople, currentProfile } = props;
  const [modalInfo, setModalInfo] = useState({
    role: "staff",
  });
  const [addModalOpen, openModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [managedByOptions, setManagedByOptions] = useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    setModalInfo({ ...modalInfo, managedBy: currentProfile._id });
  }, [currentProfile]);
  useEffect(() => {
    setErrorMessage("");
  }, [modalInfo]);

  useEffect(() => {
    getManagedByOptions({ externalId: user.sub }, currentProfile.role).then(
      (p) => {
        setManagedByOptions(p.people);
      }
    );
  }, [currentProfile]);
  const handleAddPerson = () => {
    setSubmitButtonClicked(true);
    addPerson(null, { ...modalInfo, requestingUserId: user.sub })
      .then(() =>
        getPeople({ externalId: user.sub, tab: tab }, currentProfile.role).then(
          (p) => {
            setPeople(p.people);
            openModal(false);
            setModalInfo({ role: "staff" });
            setSubmitButtonClicked(false);
          }
        )
      )
      .catch((e) => {
        setErrorMessage(e.response.data.message);
        setSubmitButtonClicked(false);
      });
  };

  return (
    <Box className={classes.peopleLibraryOuter}>
      <Box className={classes.settingsRow}>
        <Box
          className={classes.addQuestionButton}
          onClick={() => openModal(true)}
        >
          <AddIcon color="primary" />
          <Typography variant="h2">Add</Typography>
        </Box>
      </Box>
      <PersonComponentHeader />
      {people.map((p, i) => (
        <PersonComponent index={i} person={p} managers={managers} />
      ))}

      <Modal open={addModalOpen} onClose={() => openModal(false)}>
        <Box className={modalClasses.modalBase}>
          <Typography variant="h1">New Person</Typography>
          <Box className={modalClasses.typeSelector}>
            <Select
              variant="standard"
              onChange={(e) =>
                setModalInfo({ ...modalInfo, role: e.target.value })
              }
              value={modalInfo.role}
              disableUnderline={true}
            >
              <MenuItem value="staff">
                {" "}
                <Box className={modalClasses.typeItemContainer}>
                  <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                    <PersonIcon color="white" fontSize="small" />
                  </Avatar>
                  Staff
                </Box>
              </MenuItem>
              <MenuItem value="managers">
                {" "}
                <Box className={modalClasses.typeItemContainer}>
                  <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                    <SupervisorAccountIcon color="white" fontSize="small" />
                  </Avatar>
                  Manager
                </Box>
              </MenuItem>
              {currentProfile.role === "administrators" ||
              currentProfile.role === "hr" ? (
                <MenuItem value="hr">
                  {" "}
                  <Box className={modalClasses.typeItemContainer}>
                    <Avatar
                      classes={{ root: modalClasses.iconContainerAvatar }}
                    >
                      <SupervisorAccountIcon color="white" fontSize="small" />
                    </Avatar>
                    HR
                  </Box>
                </MenuItem>
              ) : null}
              {currentProfile.role === "administrators" ||
              currentProfile.role === "hr" ? (
                <MenuItem value="administrators">
                  <Box className={modalClasses.typeItemContainer}>
                    <Avatar
                      classes={{ root: modalClasses.iconContainerAvatar }}
                    >
                      <AdminPanelSettingsIcon color="white" fontSize="small" />
                    </Avatar>
                    Administrator
                  </Box>
                </MenuItem>
              ) : null}
            </Select>
          </Box>
          <Box className={modalClasses.formRow}>
            <Box className={modalClasses.typeSelector}>
              <Typography>First Name</Typography>
              <TextField
                hiddenLabel
                placeholder="John"
                variant="outlined"
                onChange={(e) => {
                  setModalInfo({ ...modalInfo, firstName: e.target.value });
                }}
              />
            </Box>
            <Box className={modalClasses.typeSelector}>
              <Typography>Last Name</Typography>
              <TextField
                hiddenLabel
                placeholder="Smith"
                variant="outlined"
                onChange={(e) => {
                  setModalInfo({ ...modalInfo, lastName: e.target.value });
                }}
              />
            </Box>
          </Box>
          <Box className={modalClasses.typeSelector}>
            <Typography>Email</Typography>
            <TextField
              hiddenLabel
              fullWidth
              placeholder="john@smith.com"
              variant="outlined"
              onChange={(e) => {
                setModalInfo({ ...modalInfo, email: e.target.value });
              }}
            />
          </Box>
          <Box className={modalClasses.typeSelector}>
            <Typography>Managed by</Typography>
            <Autocomplete
              defaultValue={
                managedByOptions.find((o) => o._id === currentProfile._id)
                  ? {
                      label: `${currentProfile.firstName} ${currentProfile.lastName}`,
                      value: currentProfile._id,
                    }
                  : {
                      label: `${
                        managedByOptions[0] ? managedByOptions[0].firstName : ""
                      } ${
                        managedByOptions[0] ? managedByOptions[0].lastName : ""
                      }`,
                      value: managedByOptions[0] ? managedByOptions[0]._id : "",
                    }
              }
              clearIcon={false}
              options={managedByOptions.map((m) => {
                return {
                  label: `${m.firstName} ${m.lastName}`,
                  value: m._id,
                };
              })}
              onChange={(e, v) => {
                setModalInfo({ ...modalInfo, managedBy: v.value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Box>

          <Box className={modalClasses.typeSelector}>
            <Typography variant="h4">{errorMessage}</Typography>
          </Box>

          <Box className={modalClasses.buttonRow}>
            <Button onClick={() => openModal(false)}>Cancel</Button>
            {submitButtonClicked ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  handleAddPerson();
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PeopleLibraryLayout;
