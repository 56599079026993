import { peoplePageStyles } from "./pagesStyles";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PeopleLibraryLayout from "../hrDashboardComponents/PeopleLibraryLayout";
import { getPeople } from "../apiHelper";
import { useOutletContext, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function People() {
  const classes = peoplePageStyles();
  const { profile } = useOutletContext();

  const [tab, setTab] = useState(profile.role === "managers" ? "team" : "all");
  const [people, setPeople] = useState([]);

  const { user } = useAuth0();

  useEffect(() => {
    getPeople({ externalId: user.sub, tab: tab }, profile.role).then(p => {
      setPeople(p.people);
    });
  }, [tab, profile]);

  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h1">People</Typography>
      <Box className={classes.peopleListContainer}>
        <Box className={classes.peopleListInnerContainer}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
              {profile.role === "administrators" || profile.role === "hr" ? (
                <Tab value="all" label="All" />
              ) : null}
              {profile.accountType !== "sports" && (
                <Tab value="team" label="My Team" />
              )}
              {profile.role === "administrators" || profile.role === "hr" ? (
                <Tab
                  value="staff"
                  label={profile.accountType !== "sports" ? "Staff" : "People"}
                />
              ) : null}
              {profile.role === "administrators" || profile.role === "hr" ? (
                <Tab value="managers" label="Managers" />
              ) : null}
              {profile.accountType !== "sports" &&
              (profile.role === "administrators" || profile.role === "hr") ? (
                <Tab value="hr" label="HR" />
              ) : null}
              {profile.role === "administrators" || profile.role === "hr" ? (
                <Tab value="administrators" label="Administrators" />
              ) : null}
              {profile.role === "administrators" || profile.role === "hr" ? (
                <Tab value="deactivated" label="Deactivated" />
              ) : null}
            </Tabs>
          </Box>
          <PeopleLibraryLayout
            people={people}
            tab={tab}
            setPeople={setPeople}
            currentProfile={profile}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default People;
