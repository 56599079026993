import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:9090/";

export const getQuestions = async (data) => {
  const url = `${apiUrl}questions`;
  const config = {
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getRandomQuestion = async (data) => {
  const url = `${apiUrl}questions/get-random-question`;
  const config = {
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const searchQuestions = async (data) => {
  const url = `${apiUrl}questions/search`;
  const config = {
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getComments = async (token, data) => {
  const url = `${apiUrl}comments`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getMyAnswers = async (token, data) => {
  const url = `${apiUrl}answer`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getPersonDetails = async (token, data) => {
  const url = `${apiUrl}people/details`;
  const config = {
    params: { ...data },
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getTenantData = async (data) => {
  const url = `${apiUrl}tenants`;
  const config = {
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getGraphData = async (token, data) => {
  const url = `${apiUrl}graph-data`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getPeople = async (data, role) => {
  const url = `${apiUrl}people`;
  const config = {
    params: { ...data, requester: role },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getManagedByOptions = async (data, role) => {
  const url = `${apiUrl}people/managed-by-options`;
  const config = {
    params: { ...data, requester: role },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getManagers = async (token, data) => {
  const url = `${apiUrl}people/managers`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getProfile = async (token, data) => {
  const url = `${apiUrl}people/profile`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getUserPermissions = async (token, data) => {
  const url = `${apiUrl}people/user-permissions`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getAvailableTimes = async (token, data) => {
  const url = `${apiUrl}people/available-times`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getBookings = async (token, data) => {
  const url = `${apiUrl}bookings`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getDatedQuestions = async (data) => {
  const url = `${apiUrl}questions/get-dated-questions`;
  const config = {
    // headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getAvailableTimesClient = async (token, data) => {
  const url = `${apiUrl}people/available-times-client`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getSettings = async (token, data) => {
  const url = `${apiUrl}settings`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};
export const getCompanyName = async (data) => {
  const url = `${apiUrl}settings/company-name`;
  const config = { params: { ...data } };
  const res = await axios.get(url, config);
  return res.data;
};
export const getQuestionDetails = async (token, data) => {
  const url = `${apiUrl}questions/get-one`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      questionId: data.questionId,
      dayOfWeek: data.dayOfWeek,
      date: data.date,
      requestingUserId: data.requestingUserId,
    },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const getAnsweredOrNot = async (token, data) => {
  const url = `${apiUrl}answer/answered-today`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...data },
  };
  const res = await axios.get(url, config);
  return res.data;
};

export const updateQuestion = async (token, questionId, data) => {
  const url = `${apiUrl}questions/update-one`;
  //TODO: AUTH
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` }
  // };
  const res = await axios.put(
    url,
    {
      questionId,
      question: data.question,
      type: data.type,
      active: data.active,
      day: data.day,
      requestingUserId: data.requestingUserId,
    }
    // config
  );
  return res.data;
};

export const updateRescheduleBooking = async (token, bookingId, data) => {
  const url = `${apiUrl}bookings/reschedule`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(
    url,
    {
      ...data,
      bookingId,
    },
    config
  );
  return res.data;
};

export const updateCancelBooking = async (token, bookingId, data) => {
  const url = `${apiUrl}bookings/cancel`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(
    url,
    {
      ...data,
      bookingId,
    },
    config
  );
  return res.data;
};
export const updateAvailableData = async (token, externalId, data) => {
  const url = `${apiUrl}people/available-times`;
  //TODO: AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(
    url,
    {
      externalId,
      availableTimes: data,
    },
    config
  );
  return res.data;
};
export const updatePerson = async (token, data) => {
  const url = `${apiUrl}people/update`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(url, { ...data }, config);
  return res.data;
};
export const deactivateUser = async (token, data) => {
  const url = `${apiUrl}people/deactivate`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(
    url,
    {
      ...data,
    },
    config
  );
  return res.data;
};
export const updateSettings = async (token, id, data) => {
  const url = `${apiUrl}settings`;
  //TODO: AUTH
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` }
  // };
  const res = await axios.put(
    url,
    {
      id,
      ...data,
    }
    // config
  );
  return res.data;
};

export const addQuestion = async (token, data, requestingUserId) => {
  const url = `${apiUrl}questions`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.post(
    url,
    {
      type: data.type,
      question: data.question,
      requestingUserId,
    },
    config
  );
  return res.data;
};
export const addAnswer = async (token, data) => {
  const url = `${apiUrl}answer`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.post(url, { ...data }, config);
  return res.data;
};
export const addComments = async (token, data) => {
  const url = `${apiUrl}comments`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.post(url, { ...data }, config);
  return res.data;
};
export const addBooking = async (token, data) => {
  const url = `${apiUrl}bookings`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.post(url, { ...data }, config);
  return res.data;
};
export const addDatedQuestion = async (token, data) => {
  const url = `${apiUrl}questions/set-question-date`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(url, { ...data }, config);
  return res.data;
};
export const removeQuestionDate = async (token, data) => {
  const url = `${apiUrl}questions/remove-question-date`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(url, { ...data }, config);
  return res.data;
};

export const confirmBooking = async (token, data) => {
  const url = `${apiUrl}bookings/confirm`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios.put(url, { ...data }, config);
  return res.data;
};

export const addPerson = async (token, data) => {
  const url = `${apiUrl}people`;
  //TODO: AUTH
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` }
  // };
  // console.log(data);
  const res = await axios.post(
    url,
    { ...data }
    // config
  );
  return res.data;
};
